<template lang='pug'>
.page-wrap
	.property
		h3 {{property.url}}
		h4 {{$route.params.date}}
		gMap(ref='map')
		PastSession(v-for='session in property.sessions' :session='session' showIp=true)

</template>

<script>

import Live from '../../mixins/Live'
import gMap from '../../map/Map'
import PastSession from '../../components/PastSession'

export default{
	mixins: [Live],
	components:{ gMap, PastSession},
	methods:{
		async getPastSessionsOnDate()
		{
			let pid = this.$route.params.key;
			let date = this.$route.params.date;
			await fetch('/api/v1/sessions?pid='+pid+'&d1='+date+'&details=true&pages=true&geo=true', {credentials: 'include'})
			.then(response => response.json())
			.then(property => {
				let ips = [];
				this.property.sessions = property.sessions[0].sessions;
				this.property.sessions.forEach(session => ips.push(session.ip));
				this.$refs.map.init(ips);
			 })
		},
		onLiveConnectionsChanged()
		{
			this.getPastSessionsOnDate();
		}
	},
	created(){
		this.init();
	}
}
</script>

<style lang="stylus" scoped>
.property
	width 94vw
	margin auto
	max-width 1200px
	h3
		margin-bottom 1vh
	h4
		margin 1vh 0
	p, a, li
		margin .6rem
		text-align left
</style>