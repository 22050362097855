<template lang='pug'>
.session
	.dateAndDuration
		p {{dateAndTime(session.date)}}
		p {{secsToMins(session.duration)}}
	.ip(v-if='showIp')
		p {{session.ip.address || session.ip}}
		a(:href="'https://www.google.com/search?q='+session.ip.lat+'%2C+'+session.ip.lng" target='_blank') {{location(session.ip)}}
	.section
		h4 History
		.page(v-for='page in session.pages')
			.new(v-if='page.path')
				.path {{page.path}}
				.time {{secsToMins(page.duration)}}
			//- temp until refactor pages array //
			.old(v-else)
				.path {{page}}
				.time 0:00
	div(v-if='session.ua').section
		h4 User Agent
		.browser {{session.ua.isMobile ? 'Mobile' : 'Desktop'}} {{session.ua.browser}} – {{session.ua.version}}
	div(v-if='session.referer').section
		h4 Referrer
		.referer {{session.referer}}
</template>

<script>

import Utils from '../mixins/Utils'

export default{
	props:{
		session:Object,
		showIp:Boolean
	},
	mixins: [Utils],
}

</script>

<style lang="stylus" scoped>

@import '../assets/css/libs/colors.styl';

.session
	text-align left
	border 1px solid $component_border_color
	padding 10px 14px
	margin-bottom 2px
	.ip, .dateAndDuration
		display flex
		padding 4px 0
		justify-content space-between
	.section
		margin 1vh 0
		h4
			margin 4px 0
	.page > .new, .page > .old
		margin 4px 0
		display flex
		justify-content space-between
</style>